import { createSlice } from "@reduxjs/toolkit";
import {
  DetailCarrier,
  Order,
  ShortOrder,
  OrderDetail,
} from "../../dto/orders";

interface NewCarrier {
  detailIndex: number;
  carrierIndex: number;
}

export interface OrderState {
  newOrder: Order;
  newShortOrders: ShortOrder[];
  newCarrier: NewCarrier | null;
}

export const createNewDetailCarrier = (type: string): DetailCarrier => ({
  supplier_carrier: {
    id: 0,
    dni: "",
    name: "",
    phone: "",
    car_plate: "",
    organization_name: "",
  },
  supplier_carrier_id: 0,
  supplier_carrier_car_plate: "",
  date: "",
  kind: type,
});

const createNewDetail = (order_id = null): OrderDetail => ({
  uuid: null,
  order_id: order_id,
  start_date: "",
  end_date: "",
  days: 1,
  carriers: [
    createNewDetailCarrier("delivery"),
    createNewDetailCarrier("pickup"),
  ],
  supplier_product: null,
  supplier_product_id: null,
  sameDriver: false,
});

export const makeNewOrder = (): Order => ({
  customer: {
    id: 0,
    name: "",
    address: "",
    phone: "",
    email: "",
    dni: "",
  },
  customer_id: 0,
  details: [createNewDetail()],
  availability: [true],
  geo_unit: {
    id: 0,
    name: "",
    parent_id: 0,
    parent: null,
  },
  geo_unit_id: 0,
  address_street: "",
  address_street_number: "",
  address_reference: "",
  kind: "lease",
});

export const makeNewShortOrder = (): ShortOrder => ({
  customer: {
    name: "",
    id: 0,
  },
  customer_id: 0,
  supplier_product_ids: [],
  start_date: "",
  end_date: null,
  total_price: 0,
});

const initialState: OrderState = {
  newOrder: makeNewOrder(),
  newShortOrders: [makeNewShortOrder()],
  newCarrier: null,
};

export const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setOrder: (state, action) => {
      state.newOrder = action.payload;
    },
    setShortOrder: (state, action) => {
      state.newShortOrders = action.payload;
    },
    setCustomerShort: (state, action) => {
      state.newShortOrders[action.payload.index].customer = action.payload;
      state.newShortOrders[action.payload.index].customer_id =
        action.payload.id;
    },
    setCustomer: (state, action) => {
      state.newOrder.customer = action.payload;
      state.newOrder.customer_id = action.payload.id;
    },
    addDetail: (state, action) => {
      state.newOrder.details.push(createNewDetail(action.payload));
      state.newOrder.availability.push(true);
    },
    addShortOrder: (state) => {
      state.newShortOrders.push(makeNewShortOrder());
    },
    removeDetail: (state, action) => {
      state.newOrder.details.splice(action.payload, 1);
      state.newOrder.availability.splice(action.payload, 1);
    },
    removeShortOrder: (state, action) => {
      state.newShortOrders.splice(action.payload, 1);
    },
    updateDetail: (state, action) => {
      const { index, ...data } = action.payload;
      state.newOrder.details[index] = data;
    },
    updateShortOrder: (state, action) => {
      const { index, ...data } = action.payload;
      state.newShortOrders[index] = data;
    },
    setAvailability: (state, action) => {
      state.newOrder.availability[action.payload.index] = action.payload.value;
    },
    setRegion: (state, action) => {
      state.newOrder.geo_unit.parent = action.payload;
    },
    setCity: (state, action) => {
      state.newOrder.geo_unit = action.payload;
      state.newOrder.geo_unit_id = action.payload.id;
    },
    setStreet: (state, action) => {
      state.newOrder.address_street = action.payload;
    },
    setNumber: (state, action) => {
      state.newOrder.address_street_number = action.payload;
    },
    setReference: (state, action) => {
      state.newOrder.address_reference = action.payload;
    },
    setNewCarrier: (state, action) => {
      state.newCarrier = action.payload;
    },
  },
});

export const {
  setCustomer,
  setCustomerShort,
  addDetail,
  addShortOrder,
  removeDetail,
  removeShortOrder,
  setRegion,
  setCity,
  setStreet,
  setNumber,
  setReference,
  setAvailability,
  setOrder,
  setShortOrder,
  setNewCarrier,
  updateDetail,
  updateShortOrder,
} = ordersSlice.actions;
export default ordersSlice.reducer;
