import { createSlice, CreateSliceOptions } from "@reduxjs/toolkit";

interface GanttFilters {
  querySearch?: string;
  kind?: string;
  view: string;
}

export interface GanttState {
  nextPage: string | null;
  prevPage: string | null;
  filters: GanttFilters;
  filtersPanelIsOpen: boolean;
}

const initialState: GanttState = {
  nextPage: null,
  prevPage: null,
  filters: {
    view: "week",
  },
  filtersPanelIsOpen: false,
};

export const ganttSlice = createSlice({
  name: "gantt",
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.nextPage = null;
      state.prevPage = null;
      state.filters = action.payload;
    },
    setFiltersPanel: (state, action) => {
      state.filtersPanelIsOpen = action.payload;
    },
  },
} as CreateSliceOptions);

export const { setFilters, setFiltersPanel } = ganttSlice.actions;
export default ganttSlice.reducer;
