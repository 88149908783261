import { createSlice } from "@reduxjs/toolkit";
import { CreateLeaseDetail } from "@/lib/dto/inventory";
import { LeadDetail } from "@/lib/dto/leads";
import moment from "moment";

interface LeadDetailState {
  details: CreateLeaseDetail[];
}

export const newLeadDetail = (leadDetail?: LeadDetail) => {
  let end_date_formatted = "";

  if (leadDetail?.start_date && leadDetail?.days) {
    const end_date = moment(leadDetail.start_date, "YYYY-MM-DD");
    end_date.add(leadDetail.days, "days");
    end_date_formatted = end_date.format("YYYY-MM-DD");
  }
  return {
    supplier_id:
      leadDetail?.supplier_id === 0 ? undefined : leadDetail?.supplier_id,
    category_id: leadDetail?.category_id,
    supplier_price: leadDetail?.supplier_price ?? 0,
    start_date: leadDetail?.start_date,
    end_date: end_date_formatted,
    lead_detail_id: leadDetail?.id,
    price: leadDetail?.price,
    price_type: leadDetail?.category?.price_type,
  };
};

const initialState: LeadDetailState = {
  details: [],
};

export const leadDetailSlice = createSlice({
  name: "leadDetailSlice",
  initialState,
  reducers: {
    initDetails: (state, action) => {
      state.details = action.payload;
    },
    updateDetail: (state, action) => {
      const { index, ...data } = action.payload;
      state.details[index] = data;
    },
  },
});

export const { updateDetail, initDetails } = leadDetailSlice.actions;
export default leadDetailSlice.reducer;
