import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useDispatch } from "react-redux";
import { addNotification } from "./notificationsSlice";
import { useSendTokenMutation } from "@/lib/services/user";

export const Notifications = ({ children }: any) => {
  const dispatch = useDispatch();

  const [sendTokenMutation] = useSendTokenMutation();

  useEffect(() => {
    console.log("pidiendo notificaciones");

    const firebaseConfig = {
      apiKey: "AIzaSyAkx9jt_skcpiICX05irps8tBEfoK0_lNQ",
      authDomain: "rendalomaq-347014.firebaseapp.com",
      projectId: "rendalomaq-347014",
      storageBucket: "rendalomaq-347014.appspot.com",
      messagingSenderId: "743424689673",
      appId: "1:743424689673:web:e090c71ca3688bd60d5ad8",
      measurementId: "G-T8G5MHV8YV",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const messaging = getMessaging(app);

    getToken(messaging, {
      vapidKey:
        "BJcvqfz1PMNKaX8Lbr04r3HKXevSuCK8i5lNqEwuViR_UWCYzsFjjvwJAA_NwEqUD8frEwLV-ILCK-ed7uQq2wY",
    })
      .then((currentToken) => {
        if (currentToken) {
          sendTokenMutation(currentToken);
          onMessage(messaging, (payload: any) => {
            dispatch(
              addNotification({
                title: payload.notification.title,
                body: payload.notification.body,
                data: payload.data,
              })
            );
            // ...
          });
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
          // ...
        }
      })
      .catch((err) => {
        console.log("An error occurred while retrieving token. ", err);
        // ...
      });
  }, []);
  return children;
};
