import { createSlice } from "@reduxjs/toolkit";
import { DocsFilters } from "@/lib/dto/leads";

interface DocsState {
  filtersPanelIsOpen: boolean;
  filters: DocsFilters;
}

const initialState: DocsState = {
  filtersPanelIsOpen: false,
  filters: {},
};

export const docsSlice = createSlice({
  name: "docs",
  initialState,
  reducers: {
    setFiltersPanel: (state, action) => {
      state.filtersPanelIsOpen = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFiltersPanel, setFilters } = docsSlice.actions;
export default docsSlice.reducer;
