import moment, { Moment } from "moment";
import { Currency } from "./dto/currency";
import { Product } from "./dto/inventory";
import { GoPage, UsageFilter } from "@/lib/dto/requests";
import _ from "cypress/types/lodash";
import { t } from "@lingui/macro";
import { ChartData } from "./dto/management";
import { Profile, User } from "./dto/user";
export const dateFormat = "DD-MM-YYYY";
export const correctDateFormat = "DD/MM/YYYY";

export const GOOGLE_API_KEY = "AIzaSyBqf1S_FdFwlnh63ZTXH7g4Aj7fed5Avnk";

export function sleep(ms: number): Promise<null> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const range = (start: number, end: number) =>
  Array.from(Array(end - start + 1).keys()).map((x) => x + start);

export class ComputePickUpDate {
  startDate: string;
  numberOfDays: number;
  date: Date;

  constructor(startDate: string, numberOfDays: number) {
    this.startDate = startDate;
    this.numberOfDays = numberOfDays;
    this.date = startDate ? new Date(this.startDate) : new Date();
  }

  compute() {
    if (!this.startDate || !this.numberOfDays) {
      return this;
    }
    this.date = moment(this.startDate).add(this.numberOfDays, "days").toDate();
    return this;
  }

  getDate(): Date {
    return this.date;
  }

  toString(): string {
    return this.date.toISOString().split("T")[0];
  }
}

export const getProductName = (product: Product | null): string => {
  const shouldShowSupplier = product && product.supplier;

  return shouldShowSupplier
    ? `${product?.supplier?.name} - ${product?.name} (${product?.supplier_internal_id})`
    : `${product?.name} (${product?.supplier_internal_id})`;
};

export const daysBetween = (
  start: Date | string,
  end: Date | string
): number => {
  const startDate = moment(start);
  const endDate = moment(end);
  return endDate.diff(startDate, "days");
};

const currencies = {
  "en-US": "USD",
  "es-CL": "CLP",
  "es-MX": "MXN",
  "pt-BR": "BRL",
};

export const moneyFormatCurrency = (
  value: number,
  locale: string = "es-CL",
  currency?: Currency | string
): string => {
  const lcl = locale?.split("-")[1];

  if (typeof currency === "string") {
    const curr = currency
      ? currency
      : currencies[locale as keyof typeof currencies];

    if (isNaN(value)) return `$${0}`;

    if (currency === "UF") {
      return `UF ${new Intl.NumberFormat(locale).format(value)}`;
    }

    if (locale === "es-MX") {
      return `$ ${curr} ${new Intl.NumberFormat(locale).format(value)}`;
    }

    if (locale === "en-US") {
      return `${curr} ${new Intl.NumberFormat(locale).format(value)}`;
    }

    return new Intl.NumberFormat(locale, {
      style: "currency",
      currency: curr,
    }).format(value);
  } else {
    const symbol = currency?.symbol ?? "$";

    const options: Intl.NumberFormatOptions = {
      minimumFractionDigits: lcl === "CL" ? 0 : 2,
      maximumFractionDigits: lcl === "CL" ? 0 : 2,
    };

    if (isNaN(value)) return `${symbol}${0}`;
    if (currency?.is_default) {
      options.style = "currency";
      options.currency = currency?.code;
      return new Intl.NumberFormat(locale ?? "es-CL", options).format(value);
    }
    return `${symbol}${new Intl.NumberFormat(locale ?? "es-CL", options).format(
      value
    )}`;
  }
};

export const moneyFormat = (value: number): string => {
  if (isNaN(value)) return `$${0}`;
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
};

export const getValueFromKey = (
  val: number,
  list: any[] | undefined,
  key1: string,
  key2: string
) => {
  if (!val) return "--";
  if (!list) return val;

  const item = list.filter((x: any) => x[key2] === val)[0];

  return item ? item[key1] : val;
};

export const nameFromId = (
  id: any,
  list: any[] | undefined,
  keyToReturn: string,
  keyToExplore = "id"
) => {
  if (!list) return id;

  const item = list.filter(
    (x: any) => x[keyToExplore].toString() === id.toString()
  )[0];

  return item ? item[keyToReturn] : id;
};

export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

export const inputFormat = (currency: Currency | null | undefined): string => {
  const symbol = currency?.symbol ?? "$";
  return symbol;
};

export const getReportQueryParams = (requestFilter: UsageFilter) => {
  const params = new URLSearchParams();
  if (requestFilter) {
    requestFilter.supplier_product_ids.forEach((id: any) =>
      params.append("supplier_product_ids", id.toString())
    );
    params.append("start_date", requestFilter.start_date);
    params.append("end_date", requestFilter.end_date);
    params.append("period_kind", requestFilter.period_kind);
    params.append("base_line_type", requestFilter.base_line_type);
  }
  return params;
};

export const defaultCurrency = (
  country: string | null | undefined
): string | null => {
  switch (country) {
    case "CL":
      return "CLP";
    case "MX":
      return "MXN";
    case "BR":
      return "BRL";
    default:
      return null;
  }
};

export const dateToUTC = (date: Date | string | null | undefined): string => {
  if (!date) return "";
  return moment(date).utc().format();
};

export const dateUTCToLocal = (
  date: Date | string | null | undefined
): string => {
  if (!date) return "";
  return moment(date).local().format();
};

export const getQueryParamsFromGoPage = (goPage: GoPage) => {
  const params = new URLSearchParams();
  if (goPage.cursor) params.append("cursor", goPage.cursor);
  if (goPage.isPrevious)
    params.append("isPrevious", goPage.isPrevious.toString());
  if (goPage.page_size) params.append("page_size", goPage.page_size.toString());
  if (goPage.search) params.append("search", goPage.search);
  if (goPage.kind) params.append("kind", goPage.kind);
  if (goPage.filters) {
    Object.keys(goPage.filters).forEach((key) => {
      if (key === "lead_kind" && goPage.filters[key] === "sale")
        params.append("is_sale", "true");
      else if (key === "lead_kind" && goPage.filters[key] === "lease")
        params.append("is_lease", "true");
      else params.append(key, goPage.filters[key]);
    });
  }
  return params;
};

export const getUrlFromGoPage = (url: string, goPage: GoPage) => {
  const params = getQueryParamsFromGoPage(goPage);
  if (params.toString() === "") return url;
  return `${url}?${params.toString()}`;
};

export const latestUUIDdigits = (uuid: string) => {
  return uuid?.slice(uuid.length - 4) ?? "";
};

export interface OptionsForSelects {
  label: string;
  value: string;
}

export const monthsBetweenDates = (
  start: Moment,
  end: Moment
): OptionsForSelects[] => {
  let interim = start.clone();
  let timeValues = [];

  while (end > interim || interim.format("M") === end.format("M")) {
    timeValues.push({
      label: interim.format("MMMM YYYY"),
      value: interim.format("YYYY-MM"),
    });
    interim.add(1, "month");
  }

  return timeValues;
};

export const yearsBetweenDates = (
  start: Moment,
  end: Moment
): OptionsForSelects[] => {
  let interim = start.clone();
  let timeValues = [];

  while (end > interim || interim.format("Y") === end.format("Y")) {
    timeValues.push({
      label: interim.format("YYYY"),
      value: interim.format("YYYY"),
    });
    interim.add(1, "year");
  }

  return timeValues;
};

export const ellipsis = (text: string, length: number) => {
  if (text.length <= length) return text;
  return `${text.slice(0, length)}...`;
};

export const firstLetterCapitalize = (name?: string) => {
  if (!name) return;
  let words = name.split(" ");
  return words
    .map((word) => {
      return word.length > 0 && word !== " "
        ? word[0].toUpperCase() + word.substring(1).toLocaleLowerCase()
        : word;
    })
    .join(" ");
};

export function excludeKey<T extends object, U extends keyof any>(
  obj: T,
  key: U
) {
  const { [key]: _, ...newObj } = obj;
  return newObj;
}

export const getCountryCodeFromLocale = (locale: string) => {
  switch (locale) {
    case "pt-BR":
      return "br";
    case "es-MX":
      return "mx";
    default:
      return "cl";
  }
};

export const contentTypeFantasyName = (contentType: string) => {
  switch (contentType) {
    case "budgets":
      return t`Budgets`;
    case "purchase-orders":
      return t`Purchase orders`;
    default:
      return contentType;
  }
};

export const contentTypeSingularFantasyName = (contentType: string) => {
  switch (contentType) {
    case "budgets":
      return t`Budget`;
    case "purchase-orders":
      return t`Purchase order`;
    default:
      return contentType;
  }
};

export const getFileNameFromURL = (url?: string) => {
  if (!url) return "";
  const split = url.split("/");
  return split[split.length - 1];
};

export const sumOfDataForCharts = (data: ChartData) => {
  // Verificar que el objeto tenga la propiedad 'series'
  if (!data || !data.series) {
    return false;
  }

  // Inicializar la variable que almacenará la suma total
  let totalSum = 0;

  // Recorrer todos los objetos dentro de 'series'
  for (let serie of data.series) {
    // Verificar que cada objeto tenga la propiedad 'data'
    if (serie.data && Array.isArray(serie.data)) {
      // Sumar todos los valores del array 'data'
      for (let value of serie.data) {
        totalSum += value;
      }
    }
  }

  // Verificar si la suma total es mayor que 0
  return totalSum;
};

export const getMinMax = (chartData: any) => {
  let allValues: any[] = [];

  chartData.series.forEach((series: any) => {
    allValues = allValues.concat(series.data);
  });

  const minValue = Math.min(...allValues);
  const maxValue = Math.max(...allValues);

  return {
    min: minValue,
    max: maxValue,
  };
};

export const shouldShowJustifyModal = (
  leadId: string,
  profile: Profile
): boolean => {
  const storedReason = localStorage.getItem("reason");
  const canEditBlockedPeriod = userCanEditBlockedPeriod(profile);

  if (!canEditBlockedPeriod) return false;

  if (storedReason) {
    const { requirementId } = JSON.parse(storedReason);
    return requirementId !== leadId;
  }
  return true;
};

export const shouldShowKeepSessionModal = (leadId: string): boolean => {
  const storedReason = localStorage.getItem("reason");
  if (storedReason) {
    const { timestamp, requirementId } = JSON.parse(storedReason);
    const storedTime = moment(timestamp);
    const currentTime = moment();
    const timeDifference = currentTime.diff(storedTime, "milliseconds");
    const fourMinutesThirtySeconds = moment
      .duration(4, "minutes")
      .add(30, "seconds")
      .asMilliseconds();
    const fiveMinutes = moment.duration(5, "minutes").asMilliseconds();

    if (timeDifference >= fiveMinutes || `${requirementId}` !== `${leadId}`) {
      console.log(
        "borrar util",
        timeDifference,
        fourMinutesThirtySeconds,
        requirementId,
        leadId
      );
      localStorage.removeItem("reason");
      return false;
    } else if (timeDifference >= fourMinutesThirtySeconds) {
      return true;
    }
  }
  return false;
};

export const userCanEditBlockedPeriod = (user: Profile): boolean => {
  return user?.user?.is_superuser ||
    user?.user?.permissions?.includes("can_edit_closed_details")
    ? true
    : false;
};
