import { createSlice, CreateSliceOptions } from "@reduxjs/toolkit";

export interface GeoState {
  isFiltersPanelOpen: boolean;
}

const initialState: GeoState = {
  isFiltersPanelOpen: false,
};

export const geoSlice = createSlice({
  name: "geo",
  initialState,
  reducers: {
    setFiltersPanel: (state, action) => {
      state.isFiltersPanelOpen = action.payload;
    },
  },
} as CreateSliceOptions);

export const { setFiltersPanel } = geoSlice.actions;
export default geoSlice.reducer;
