import { useMemo } from "react";
import moment from "moment";

export function isOnline(lastUpdate?: string): boolean {
  if (!lastUpdate) return false;
  const onlineThreshold = 10 * 60 * 1000;
  return moment().diff(moment(lastUpdate)) < onlineThreshold;
}

interface Props {
  isOnline?: boolean;
}

export default function Default({ isOnline }: Props) {
  return (
    <>
      {isOnline ? (
        <p className="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full text-gray-800">
          <svg
            className="-ml-0.5 mr-1.5 h-2 w-2 text-green-400"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={3} />
          </svg>
          En línea
        </p>
      ) : (
        <p className="px-2 inline-flex items-center text-xs leading-5 font-semibold rounded-full text-gray-800">
          <svg
            className="-ml-0.5 mr-1.5 h-2 w-2 text-red-400"
            fill="currentColor"
            viewBox="0 0 8 8"
          >
            <circle cx={4} cy={4} r={3} />
          </svg>
          Fuera de línea
        </p>
      )}
    </>
  );
}
