import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { getUserToken } from "../features/auth/services";
import {
  GoPage,
  ListPageResults,
  BrandModelFilters,
  UsageFilter,
} from "../dto/requests";
import {
  Brand,
  BrandModel,
  Category,
  Product,
  ModelDetail,
  NewProduct,
  ProductLocation,
  Attribute,
  ProductImage,
  ImagesPayload,
  LocationGoPage,
  AttributeFilter,
  ProductConsideration,
  ProductAvailabilityUpdate,
  NewProductOtherSupplier,
  ProductGPS,
  ProductGPSModel,
  BrandModelPayload,
  BrandPayload,
  CategoryPayload,
  CategoryImage,
  ProductForSaleData,
} from "../dto/inventory";
import { PAGE_SIZE, PAGE_SIZE_INVENTORY } from "./config";
import { isOnline } from "../features/geolocation/IsAvailable";
import { Order } from "../dto/orders";
import { getReportQueryParams } from "@/lib/utils";
import { setError } from "../features/error/slice";
import { LeadItem } from "../dto/leads";

interface UpdateProductBaseLine {
  supplier_product_id: number;
  base_line_type: string;
}

export const parseAttributes = (attributes: any[]): Attribute[] => {
  let attributesList: Attribute[] = [];
  if (Object.entries(attributes).length) {
    Object.entries(attributes).map(([key, value]) => {
      attributesList.push({
        key: key,
        value: value,
      });
    });
  }

  return attributesList;
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${API_HOST}/suppliers/v100/`,
  prepareHeaders: (headers) => {
    // FIXME: centralizar
    headers.set("Authorization", `Token ${getUserToken()}`);
    return headers;
  },
});

interface ApiResult {
  data: {
    code: string;
    expected_by_client: boolean;
  };
}

const baseQueryWithError: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const { dispatch } = api;

  const isMutation = args.hasOwnProperty("body");
  if (isMutation) dispatch(setError(null));

  let result = await baseQuery(args, api, extraOptions);
  let errorData = result.error as ApiResult;
  if (isMutation && result.error && !errorData?.data?.expected_by_client) {
    dispatch(setError(`${errorData?.data?.code}`));
  }

  return result;
};

export const inventoryApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: baseQueryWithError,
  tagTypes: [
    "Products",
    "Order",
    "Dashboard",
    "Budgets",
    "PurchaseOrders",
    "Binnacle",
    "Lead",
    "SearchForSupplierLead",
    "LeadKanban",
    "LeadStatus",
    "LeadDetails",
    "OrderDetails",
    "InactiveOrderDetails",
    "OrderInvoices",
    "OrderAttachments",
    "OrderExtensions",
    "BudgetInvoices",
    "PurchaseOrderInvoices",
    "OperationTasks",
    "LeadAttachments",
    "Brands",
    "BrandsModels",
    "BudgetsConditions",
    "GeoBudgetsConditions",
    "Categories",
    "BudgetsDetails",
    "LeadMovements",
    "PurchaseOrdersDetails",
    "LeadTechnicalIssues",
    "LeadStatusLogs",
    "Schedule",
    "LeadResume",
    "Payments",
    "Customers",
    "Suppliers",
    "Operability",
    "Comments",
    "SuppliersFee",
    "SupplierProducts",
    "Branches",
    "LeadCreditNotesList",
    "Organization",
  ],
  endpoints: (builder) => ({
    getAllProducts: builder.query<ListPageResults<Product>, GoPage | null>({
      providesTags: ["Products"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (!goPage?.page_size) {
          params.append("page_size", PAGE_SIZE_INVENTORY.toString());
        } else {
          params.append("page_size", `${goPage?.page_size}`);
        }
        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.kind) params.append(goPage.kind, "true");
          if (goPage.operation_availability)
            params.append(
              "operation_availability",
              goPage.operation_availability
            );
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "categories") {
                goPage.filters.categories?.forEach((id: number) =>
                  params.append("category_ids", `${id}`)
                );
              } else if (key === "providers") {
                goPage.filters.providers?.forEach((id: number) =>
                  params.append("supplier_ids", `${id}`)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `supplier-products?${params.toString()}`;
      },
      transformResponse: (response: any) => {
        return {
          ...response,
          results: response.results.map((product: Product) => {
            return {
              ...product,
              searchName: `${product.name} (${product.supplier_internal_id})`,
            };
          }),
        };
      },
    }),

    getAllProductsLight: builder.query<ListPageResults<Product>, GoPage | null>(
      {
        providesTags: ["Products"],
        query: (goPage) => {
          const params = new URLSearchParams();
          if (!goPage?.page_size) {
            params.append("page_size", PAGE_SIZE.toString());
          } else {
            params.append("page_size", `${goPage?.page_size}`);
          }
          if (goPage) {
            if (goPage.cursor) params.append("cursor", goPage.cursor);
            if (goPage.isPrevious) params.append("previous", "");
            if (goPage.kind) params.append(goPage.kind, "true");
            if (goPage.search) params.append("search", goPage.search);
            if (goPage.filters) {
              Object.keys(goPage.filters).forEach((key) => {
                if (key === "categories") {
                  goPage.filters.categories?.forEach((id: number) =>
                    params.append("category_ids", `${id}`)
                  );
                } else if (key === "providers") {
                  goPage.filters.providers?.forEach((id: number) =>
                    params.append("supplier_ids", `${id}`)
                  );
                } else {
                  params.append(key, goPage.filters[key]);
                }
              });
            }
          }
          return `supplier-products-light?${params.toString()}`;
        },
        transformResponse: (response: any) => {
          return {
            ...response,
            results: response.results.map((product: Product) => {
              return {
                ...product,
                searchName: `${product.name} (${product.supplier_internal_id})`,
              };
            }),
          };
        },
      }
    ),

    getAllProductsRegister: builder.query<Product[], UsageFilter | null>({
      providesTags: ["Products"],
      query: (usageFilter) => {
        const params = getReportQueryParams(usageFilter!);
        return `supplier-products/reports/usage?${params.toString()}`;
      },
    }),

    getProductImages: builder.query<ProductImage[], number>({
      providesTags: ["Products"],
      query: (productId) => `supplier-products/${productId}/images`,
    }),

    saveProductImages: builder.mutation<ProductImage[], ImagesPayload>({
      invalidatesTags: ["Products"],
      query: ({ images, productId }) => ({
        url: `supplier-products/${productId}/images`,
        body: images,
        method: "PUT",
      }),
    }),

    updateProductImages: builder.mutation<
      void,
      { id: number; images: ProductImage[] }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, images }) => ({
        url: `supplier-products/${id}/images/update-public`,
        body: images,
        method: "PUT",
      }),
    }),

    getCategories: builder.query<
      Category[],
      { parent?: number | null | undefined; only_children?: boolean }
    >({
      providesTags: ["Categories"],
      query: ({ parent, only_children }) => {
        const params = new URLSearchParams();
        if (parent) params.append("parent_id", parent.toString());
        if (only_children) params.append("only_children", `${only_children}`);

        return `categories?${params.toString()}`;
      },
      transformResponse: (response: any[]): Category[] => {
        return response.map((cat) => {
          return {
            ...cat,
            attributesList: parseAttributes(cat.attributes),
          };
        });
      },
    }),

    getCurrentCategories: builder.query<Category[], void>({
      query: () => `current-categories`,
    }),

    getCategoryFilters: builder.query<AttributeFilter[], void>({
      query: () => `category-filters`,
    }),

    getBrands: builder.query<Brand[], void>({
      providesTags: ["Brands"],
      query: () => `brands`,
    }),

    getModels: builder.query<BrandModel[], BrandModelFilters>({
      providesTags: ["BrandsModels"],
      query: (filters) => {
        return `brands-models?brand_id=${filters.brand}`;
      },
    }),

    getHierarchy: builder.query<ModelDetail, number>({
      query: (model_id) => `brands-models/${model_id}`,
    }),

    getProduct: builder.query<Product, number>({
      providesTags: ["Products"],
      query: (productId) => ({
        url: `supplier-products/${productId}`,
        method: "GET",
      }),
    }),

    getProductOrders: builder.query<Order[], number>({
      query: (productId) => ({
        url: `supplier-products/${productId}/orders`,
        method: "GET",
      }),
    }),

    createProduct: builder.mutation<Product, NewProduct>({
      invalidatesTags: ["Products"],
      query: (product) => ({
        url: "supplier-products",
        body: product,
        method: "POST",
      }),
    }),

    updateProduct: builder.mutation<Product, NewProduct & Pick<Product, "id">>({
      invalidatesTags: ["Products"],
      query: ({ id, ...product }) => ({
        url: `supplier-products/${id}`,
        body: product,
        method: "PUT",
      }),
    }),

    deleteProduct: builder.mutation<void, number>({
      invalidatesTags: ["Products"],
      query: (id) => ({
        url: `supplier-products/${id}`,
        method: "DELETE",
      }),
    }),

    updateProductBaseLine: builder.mutation<void, UpdateProductBaseLine>({
      invalidatesTags: ["Products"],
      query: (payload) => ({
        url: `supplier-products/${payload.supplier_product_id}/update-base-line`,
        method: "POST",
        body: {
          base_line_type: payload.base_line_type,
        },
      }),
    }),

    getInventoryLocations: builder.query<
      ProductLocation[],
      LocationGoPage | null
    >({
      providesTags: ["Products"],
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.category_id)
            params.append("category_id", goPage.category_id);
          if (goPage.supplier_id)
            params.append("supplier_id", goPage.supplier_id);
          if (goPage.availability)
            params.append("availability", goPage.availability);
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.shared_key) params.append("shared_key", goPage.shared_key);
        }
        return `supplier-products/gps-data?${params.toString()}`;
      },
      transformResponse: (response: any[]): ProductLocation[] => {
        return response.map((product) => {
          return {
            ...product,
            is_online: isOnline(product.position_data?.device?.lastUpdate),
            searchName: `${product.name} (${product.supplier_internal_id})`,
          };
        });
      },
    }),

    createProductConsideration: builder.mutation<
      void,
      { id: number; data: ProductConsideration }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, data }) => ({
        url: `supplier-products/${id}/considerations`,
        method: "POST",
        body: data,
      }),
    }),

    deleteProductConsideration: builder.mutation<
      void,
      { id: number; considerationId: number }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, considerationId }) => ({
        url: `supplier-products/${id}/considerations/${considerationId}`,
        method: "DELETE",
      }),
    }),

    partialUpdateSupplierProduct: builder.mutation<
      void,
      {
        id: number;
        data: Partial<Product> | FormData | ProductAvailabilityUpdate;
      }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, data }) => ({
        url: `supplier-products/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),
    generateProductContent: builder.mutation<void, { id: number }>({
      invalidatesTags: ["Products"],
      query: ({ id }) => ({
        url: `supplier_products/${id}/generate-content`,
        method: "POST",
      }),
    }),

    createProductOtherSupplier: builder.mutation<
      Product,
      NewProductOtherSupplier
    >({
      invalidatesTags: ["Products"],
      query: (product) => ({
        url: `${API_HOST}/suppliers/v102/supplier-products`,
        body: product,
        method: "POST",
      }),
    }),

    createGpsSupplierProduct: builder.mutation<
      ProductGPS,
      { id: number; payload: ProductGPS }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, payload }) => ({
        url: `supplier-products/${id}/gps`,
        body: payload,
        method: "POST",
      }),
    }),

    createOrUpdateGpsSupplierProduct: builder.mutation<
      ProductGPS,
      { id: number; payload: ProductGPS }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, payload }) => ({
        url: `supplier-products/${id}/update-create-base-line`,
        body: payload,
        method: "POST",
      }),
    }),

    getGpsModels: builder.query<ProductGPSModel[], void>({
      query: () => `gps-models`,
    }),

    createBrand: builder.mutation<Brand, BrandPayload>({
      invalidatesTags: ["Brands"],
      query: (data) => ({
        url: `${API_HOST}/suppliers/v101/brands`,
        body: data,
        method: "POST",
      }),
    }),

    createBrandModel: builder.mutation<BrandModel, BrandModelPayload>({
      invalidatesTags: ["BrandsModels"],
      query: (payload) => ({
        url: `${API_HOST}/suppliers/v101/brands-models`,
        body: payload,
        method: "POST",
      }),
    }),

    getBrandModel: builder.query<BrandModel, number>({
      query: (id) => `${API_HOST}/suppliers/v102/brand-model/${id}`,
    }),

    getAllCategories: builder.query<ListPageResults<Category>, GoPage>({
      providesTags: ["Categories"],
      query: (goPage) => {
        const params = new URLSearchParams();

        if (goPage) {
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.order_by) params.append("order_by", goPage.order_by);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              params.append(key, goPage.filters[key]);
            });
          }
          params.append(
            "page_size",
            goPage.page_size
              ? `${goPage?.page_size}`
              : PAGE_SIZE_INVENTORY.toString()
          );
        }
        return `${API_HOST}/suppliers/v102/categories?${params.toString()}`;
      },
    }),

    createCategory: builder.mutation<Category, FormData>({
      invalidatesTags: ["Categories"],
      query: (data) => ({
        url: `${API_HOST}/suppliers/v102/categories`,
        body: data,
        method: "POST",
      }),
    }),

    updateCategory: builder.mutation<
      Category,
      { id: number; payload: FormData }
    >({
      invalidatesTags: ["Categories"],
      query: ({ id, payload }) => ({
        url: `${API_HOST}/suppliers/v102/categories/${id}`,
        body: payload,
        method: "PUT",
      }),
    }),

    getCategoryImages: builder.query<CategoryImage[], number>({
      query: (id) => `${API_HOST}/suppliers/v102/categories/${id}/images`,
    }),

    deleteCategory: builder.mutation<void, number>({
      invalidatesTags: ["Categories"],
      query: (id) => ({
        url: `${API_HOST}/suppliers/v102/categories/${id}`,
        method: "DELETE",
      }),
    }),

    updateProductForSaleData: builder.mutation<
      void,
      { id: number; payload: ProductForSaleData }
    >({
      invalidatesTags: ["Products"],
      query: ({ id, payload }) => ({
        url: `products/${id}`,
        method: "PUT",
        body: payload,
      }),
    }),

    getRentalHistoryBySupplierProduct: builder.query<LeadItem[], number>({
      query: (id) =>
        `${API_HOST}/suppliers/v102/supplier-products/${id}/rental-history`,
    }),
  }),
});

export const {
  useGetAllProductsQuery,
  useGetAllProductsLightQuery,
  useGetAllProductsRegisterQuery,
  useGetProductImagesQuery,
  useSaveProductImagesMutation,
  useGetCategoriesQuery,
  useGetCurrentCategoriesQuery,
  useGetCategoryFiltersQuery,
  useGetBrandsQuery,
  useGetModelsQuery,
  useGetHierarchyQuery,
  useGetProductQuery,
  useGetProductOrdersQuery,
  useCreateProductMutation,
  useUpdateProductMutation,
  useDeleteProductMutation,
  useGetInventoryLocationsQuery,
  useUpdateProductBaseLineMutation,
  useUpdateProductImagesMutation,
  useCreateProductConsiderationMutation,
  useDeleteProductConsiderationMutation,
  usePartialUpdateSupplierProductMutation,
  useGenerateProductContentMutation,
  useCreateProductOtherSupplierMutation,
  useCreateGpsSupplierProductMutation,
  useGetGpsModelsQuery,
  useCreateBrandMutation,
  useCreateBrandModelMutation,
  useCreateOrUpdateGpsSupplierProductMutation,
  useGetBrandModelQuery,
  useCreateCategoryMutation,
  useGetAllCategoriesQuery,
  useGetCategoryImagesQuery,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation,
  useUpdateProductForSaleDataMutation,
  useGetRentalHistoryBySupplierProductQuery,
} = inventoryApi;
