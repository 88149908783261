import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_HOST } from "@/lib/client/config";
import { Currency } from "@/lib/dto/currency";
import { Notification } from "@/lib/dto/notifications";
import { GoPage, ListPageResults, RequestResult } from "@/lib/dto/requests";
import {
  User,
  Profile,
  SupplierConfig,
  AccessToken,
  DetailUsers,
  UserData,
} from "@/lib/dto/user";
import { getUserToken } from "@/lib/features/auth/services";

interface ChangeUserPayload {
  supplier_id?: number;
}

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/suppliers/v100/`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${getUserToken()}`);
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Customer", "User", "SupplierUser"],
  endpoints: (builder) => ({
    getProfile: builder.query<Profile, void | null>({
      providesTags: ["User"],
      query: () => "supplier-users/me",
      transformResponse: (response: any) => {
        return {
          ...response,
          supplier: {
            ...response.supplier,
            tax_value: response.supplier.tax_value * 100,
          },
        };
      },
    }),

    getNotifications: builder.query<ListPageResults<Notification>, void>({
      providesTags: ["User"],
      query: () => "supplier-users/notifications",
    }),

    getSupplierConfig: builder.query<SupplierConfig, void>({
      query: () => "supplier-config",
    }),

    getCurrencies: builder.query<Currency[], String>({
      query: (locale) => `${API_HOST}/public/v101/${locale}/currencies`,
    }),

    sendToken: builder.mutation({
      query: (token) => ({
        method: "POST",
        url: `${API_HOST}/users/v100/devices/`,
        body: {
          type: "web",
          registration_id: token,
        },
      }),
    }),

    getSupplierUsers: builder.query<User[], void>({
      query: () => `${API_HOST}/suppliers/v101/supplier-users`,
      transformResponse: (response: any) => {
        return response.map((userObj: DetailUsers) => {
          const { user } = userObj;
          return {
            ...userObj,
            searchName:
              user.first_name || user.last_name
                ? `${user.first_name} ${user.last_name}`
                : user.email,
          };
        });
      },
    }),
    getSupplierUsersPaginated: builder.query<
      ListPageResults<UserData>,
      GoPage | null
    >({
      query: (goPage) => {
        const params = new URLSearchParams();
        if (goPage) {
          if (goPage.page_size) params.set("page_size", `${goPage.page_size}`);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
          if (goPage.kind) params.append(goPage.kind, "true");
          if (goPage.kind) params.append("kind", goPage.kind);
          if (goPage.search) params.append("search", goPage.search);
          if (goPage.filters) {
            Object.keys(goPage.filters).forEach((key) => {
              if (key === "supplier_id") {
                goPage.filters.supplier_id.forEach((id: any) =>
                  params.append("supplier_id", id)
                );
              } else {
                params.append(key, goPage.filters[key]);
              }
            });
          }
        }
        return `${API_HOST}/suppliers/v102/supplier-users?${params.toString()}`;
      },
      providesTags: ["SupplierUser"],
    }),

    postSupplierUser: builder.mutation<RequestResult, Partial<UserData>>({
      query: (payload) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v102/supplier-users`,
          body: payload,
        };
      },
      invalidatesTags: ["SupplierUser"],
    }),

    putSupplierUser: builder.mutation<RequestResult, UserData>({
      query: (payload) => {
        return {
          method: "PATCH",
          url: `${API_HOST}/suppliers/v102/supplier-users/${payload.id}`,
          body: payload,
        };
      },
      invalidatesTags: ["SupplierUser"],
    }),
    deleteSupplierUser: builder.mutation<RequestResult, number>({
      query: (id) => {
        return {
          method: "DELETE",
          url: `${API_HOST}/suppliers/v102/supplier-users/${id}`,
        };
      },
      invalidatesTags: ["SupplierUser"],
    }),

    changeUserSupplier: builder.mutation<void, ChangeUserPayload>({
      query: (payload) => {
        return {
          method: "POST",
          url: `${API_HOST}/suppliers/v101/change-user-supplier/`,
          body: payload,
        };
      },
      invalidatesTags: ["User"],
    }),

    getAccessToken: builder.query<AccessToken, void>({
      query: () => "supplier-users/access-token",
    }),
  }),
});

export const {
  useGetProfileQuery,
  useLazyGetProfileQuery,
  useGetNotificationsQuery,
  useGetSupplierConfigQuery,
  useGetCurrenciesQuery,
  useSendTokenMutation,
  useGetSupplierUsersQuery,
  useGetSupplierUsersPaginatedQuery,
  usePostSupplierUserMutation,
  usePutSupplierUserMutation,
  useDeleteSupplierUserMutation,
  useChangeUserSupplierMutation,
  useGetAccessTokenQuery,
} = userApi;
