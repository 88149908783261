import { createSlice } from "@reduxjs/toolkit";

let localOpenSidebar;
let localSubMenuOpen;

if (typeof window !== "undefined") {
  localOpenSidebar =
    localStorage.getItem("isOpen") !== null
      ? JSON.parse(localStorage.getItem("isOpen"))
      : true;
  localSubMenuOpen =
    localStorage.getItem("subMenuOpen") !== null &&
    JSON.parse(localStorage.getItem("subMenuOpen"));
}

const initialState = {
  isOpen: localOpenSidebar,
  expandedSubMenuName: "",
  subMenuOpen: localSubMenuOpen,
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    openSidebar: (state, action) => {
      state.isOpen = true;
      localStorage.setItem("isOpen", JSON.stringify(true));
    },
    closeSidebar: (state, action) => {
      state.isOpen = false;
      localStorage.setItem("isOpen", JSON.stringify(false));
    },
    currentSidebarBtn: (state, { payload }) => {
      state.expandedSubMenuName = payload.name;
      localStorage.setItem("expandedSubMenuName", JSON.stringify(payload.name));
    },
    toggleSubMenu: (state, { payload }) => {
      state.subMenuOpen = payload;
      localStorage.setItem("subMenuOpen", JSON.stringify(payload));
    },
  },
});

export const { openSidebar, closeSidebar, currentSidebarBtn, toggleSubMenu } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
