import { createSlice } from "@reduxjs/toolkit";

export const globalErrorSlice = createSlice({
  name: "globalError",
  initialState: { error: null },
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
  },
});

export const { setError } = globalErrorSlice.actions;
export default globalErrorSlice.reducer;
