import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { API_HOST } from "../client/config";
import { dashboardFilters } from "../dto/management";
import { inventoryApi } from "./inventory";

export const managementApi = inventoryApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDashboardFilters: builder.query<dashboardFilters, void | null>({
      query: () => {
        return `${API_HOST}/suppliers/v102/business/dashboard/filters`;
      },
    }),
    getLeadsPerDay: builder.query({
      query: ({ filters }) => {
        const params = new URLSearchParams();
        if (filters.month) params.append("month", filters.month);
        if (filters.industry) params.append("industry", filters.industry);
        if (filters.service_area_ids) {
          filters.service_area_ids.forEach((id: string) => {
            params.append("service_area_ids", `${id}`);
          });
        }
        return `${API_HOST}/suppliers/v102/business/dashboard/leads-per-day?${params.toString()}`;
      },
    }),
    getDashboardLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/leases?year=${year}`;
      },
    }),
    getDashboardSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/sales?year=${year}`;
      },
    }),
    getDashboardTotalNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/total-net?year=${year}`;
      },
    }),
    getDashboardSalesNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/sales-net?year=${year}`;
      },
    }),
    getDashboardLeasesNet: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/leases-net?year=${year}`;
      },
    }),
    getDashboardNetMargin: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/net-margin?year=${year}`;
      },
    }),
    getDashboardSalesMargin: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/margin-sales?year=${year}`;
      },
    }),
    getDashboardLeasesMargin: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/margin-leases?year=${year}`;
      },
    }),
    getDashboardLeads: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/leads?year=${year}`;
      },
    }),
    getDashboardClosedSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/closed-sales?year=${year}`;
      },
    }),
    getDashboardClosedLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/closed-leases?year=${year}`;
      },
    }),
    getDashboardTicketSales: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/ticket-for-sales?year=${year}`;
      },
    }),
    getDashboardTicketLeases: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/ticket-for-leases?year=${year}`;
      },
    }),
    getDashboardLeaseDays: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/lease-days?year=${year}`;
      },
    }),
    getDashboardInvoicedByBusiness: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/invoiced-by-business?year=${year}`;
      },
    }),
    getDashboardMarginByBusiness: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/margin-by-business?year=${year}`;
      },
    }),
    getDashboardTakeRate: builder.query({
      query: (year) => {
        return `${API_HOST}/suppliers/v102/business/dashboard/on-demand/take-rate?year=${year}`;
      },
    }),
  }),
});

export const {
  useGetDashboardFiltersQuery,
  useGetLeadsPerDayQuery,
  useGetDashboardLeasesQuery,
  useGetDashboardSalesQuery,
  useGetDashboardTotalNetQuery,
  useGetDashboardSalesNetQuery,
  useGetDashboardLeasesNetQuery,
  useGetDashboardNetMarginQuery,
  useGetDashboardSalesMarginQuery,
  useGetDashboardLeasesMarginQuery,
  useGetDashboardLeadsQuery,
  useGetDashboardClosedSalesQuery,
  useGetDashboardClosedLeasesQuery,
  useGetDashboardTicketSalesQuery,
  useGetDashboardTicketLeasesQuery,
  useGetDashboardLeaseDaysQuery,
  useGetDashboardInvoicedByBusinessQuery,
  useGetDashboardMarginByBusinessQuery,
  useGetDashboardTakeRateQuery,
} = managementApi;
