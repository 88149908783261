import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginGoogleResponse, LoginPayload, LoginResponse } from "../dto/user";
import { setUserToken } from "../features/auth/services";
import { userApi } from "./user";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}`,
  }),
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginPayload>({
      query: (payload) => ({
        url: "/token-auth/",
        body: payload,
        method: "POST",
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(userApi.util.invalidateTags(["User"]));
      },
      transformResponse: (response: any) => {
        setUserToken(response.token);
        return response;
      },
    }),

    resetPassword: builder.mutation({
      query: (email) => ({
        url: "/public/v100/request-reset-password",
        body: { email },
        method: "POST",
      }),
    }),

    changePassword: builder.mutation({
      query: (payload) => ({
        url: `/public/v100/change-password/${payload.resetCode}`,
        body: {
          password_1: payload.password1,
          password_2: payload.password2,
        },
        method: "PUT",
      }),
    }),
    googleLogin: builder.mutation<LoginGoogleResponse, string>({
      query: (code) => ({
        url: "/users/v100/google/signup",
        body: { code },
        method: "POST",
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useGoogleLoginMutation,
} = loginApi;
