import { createSlice } from "@reduxjs/toolkit";
import { OperationTask } from "../../dto/orders";

export interface TaskItemState {
  taskToEdit?: OperationTask;
}

const initialState: TaskItemState = {
  taskToEdit: undefined,
};

export const taskItemSlice = createSlice({
  name: "taskItem",
  initialState,
  reducers: {
    setTaskToEdit: (state, action) => {
      state.taskToEdit = action.payload;
    },
  },
});

export const { setTaskToEdit } = taskItemSlice.actions;
export default taskItemSlice.reducer;
