import { createSlice } from "@reduxjs/toolkit";
import { Lead, LeadFilters } from "@/lib/dto/leads";

interface LeadState {
  lead: Lead;
  filtersPanelIsOpen: boolean;
  kanbanFilters: LeadFilters | {};
  listFilters: LeadFilters | {};
}

export const newLead = () => ({
  id: undefined,
  first_name: "",
  last_name: "",
  email: "",
  phone: "",
  geo_unit_id: 0,
  geo_unit: undefined,
  organization_name: "",
  organization_dni: "",
  status_id: undefined,
  status_reason: "",
  payment_info: "",
  terms_and_conditions: "",
  channel: "undefined",
  details: [],
  kind: "lease",
  coupon: undefined,
  discount_value: undefined,
  assigned_to_supplier_user_ids: [],
  assigned_to_supplier_users: [],
  is_archived: false,
  what_customer_wants: "",
});

const initialState: LeadState = {
  lead: newLead(),
  filtersPanelIsOpen: false,
  listFilters: {},
  kanbanFilters: {},
};

export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    updateLead: (state, action) => {
      state.lead = action.payload;
    },
    addNewMachine: (state) => {
      state.lead.details?.push({
        category_id: undefined,
        category: null,
        start_date: "",
        days: 1,
        price: 0,
        supplier_id: 0,
        supplier_price: 0,
        transportation_price: 0,
        includes_field_training: false,
        includes_technical_support: false,
        period_rate: "daily",
        budget_text: "",
      });
    },
    removeMachine: (state, action) => {
      state.lead.details?.splice(action.payload, 1);
    },
    updateMachine: (state, action) => {
      const { index, ...data } = action.payload;
      if (state.lead.details) state.lead.details[index] = data;
    },
    setFiltersPanel: (state, action) => {
      state.filtersPanelIsOpen = action.payload;
    },
    setKanbanFilters: (state, action) => {
      state.kanbanFilters = action.payload;
    },
    setListFilters: (state, action) => {
      state.listFilters = action.payload;
    },
  },
});

export const {
  updateLead,
  addNewMachine,
  removeMachine,
  updateMachine,
  setFiltersPanel,
  setKanbanFilters,
  setListFilters,
} = leadSlice.actions;
export default leadSlice.reducer;
