import { createSlice } from "@reduxjs/toolkit";
import { Notification } from "@/lib/dto/notifications";
import { stat } from "fs";

export interface NotificationState {
  notifications: Notification[];
  isUpdatedRequirementsList: boolean;
}

const initialState: NotificationState = {
  notifications: [],
  isUpdatedRequirementsList: true,
};

export const notificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    addNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
      state.isUpdatedRequirementsList = false;
    },
    setIsUpdatedRequirementsList: (state, action) => {
      state.isUpdatedRequirementsList = action.payload;
    },
    removeNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (_, i) => i !== action.payload
      );
    },
  },
});

export const {
  addNotification,
  removeNotification,
  setIsUpdatedRequirementsList,
} = notificationSlice.actions;
export default notificationSlice.reducer;
