// Quizas esto debe ser un provider

export function setUserToken(token: string) {
  localStorage.setItem("token", token);
}

export function getUserToken(): string | null {
  if (!window) return null;
  return localStorage.getItem("token");
}

export function removeUserToken() {
  localStorage.removeItem("token");
}
