import "../styles/globals.css";
import type { AppProps } from "next/app";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { store } from "../lib/store";
import { Auth } from "../lib/features/auth/container";
import { DashboardPage } from "../lib/features/auth/DashboardPage";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useEffect } from "react";
import { useRouter } from "next/router";
import { Notifications } from "@/lib/features/notifications/Notifications";
import "@fontsource/nunito-sans/900.css";
import "@fontsource/nunito-sans/700.css";
import "@fontsource/nunito-sans/600.css";
import "@fontsource/nunito-sans";
import "nextjs-breadcrumbs/dist/index.css";
import "moment/locale/es";
import "moment/locale/pt";
import moment from "moment";
import { Analytics } from "@vercel/analytics/react";
import { messages } from "../lib/locales/es/messages";

i18n.load("es", messages);
i18n.activate("es");

function MyApp({ Component, pageProps }: AppProps) {
  const { locale } = useRouter();
  const DashComponent = Component as DashboardPage;

  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? "" });
    }
  }, []);

  useEffect(() => {
    async function load(locale: any) {
      const { messages } = await import(`../lib/locales/${locale}/messages`);

      i18n.load(locale, messages);
      i18n.activate(locale);
    }

    load(locale);

    moment.locale(locale?.split("-")[0] ?? "es");
  }, [locale]);

  return (
    <Provider store={store}>
      <Analytics />
      <I18nProvider i18n={i18n}>
        {DashComponent.auth ? (
          <Auth isRendalo={DashComponent.isRendalo}>
            <Notifications>
              <DashComponent {...pageProps} />
            </Notifications>
          </Auth>
        ) : (
          <DashComponent {...pageProps} />
        )}
      </I18nProvider>
    </Provider>
  );
}

export default MyApp;
