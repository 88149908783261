import { API_HOST } from "../client/config";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getUserToken } from "../features/auth/services";
import { GeoUnit, SharedSupplierProducts } from "@/lib/dto/geounit";
import { ListPageResults } from "../dto/requests";

export const geoApi = createApi({
  reducerPath: "geoApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/suppliers/v100/`,
    prepareHeaders: (headers) => {
      // FIXME: centralizar
      headers.set("Authorization", `Token ${getUserToken()}`);
      return headers;
    },
  }),
  tagTypes: ["Sharing"],
  endpoints: (builder) => ({
    getRegions: builder.query<GeoUnit[], null>({
      query: () => "geo-units",
    }),
    getCities: builder.query<GeoUnit[], number | null | undefined>({
      query: (regionId = -1) => `geo-units?parent_id=${regionId}`,
    }),
    getSharedSupplierProducts: builder.query<
      ListPageResults<SharedSupplierProducts>,
      void
    >({
      query: () => `${API_HOST}/suppliers/v101/shared-supplier-products/`,
      providesTags: ["Sharing"],
    }),
    postSharedSupplierProducts: builder.mutation<
      SharedSupplierProducts,
      { payload: SharedSupplierProducts }
    >({
      query: ({ payload }) => ({
        url: `${API_HOST}/suppliers/v101/shared-supplier-products/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["Sharing"],
    }),
    deleteSharedSupplierProducts: builder.mutation<any, number>({
      query: (id) => ({
        url: `${API_HOST}/suppliers/v101/shared-supplier-products/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Sharing"],
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useGetCitiesQuery,
  useGetSharedSupplierProductsQuery,
  usePostSharedSupplierProductsMutation,
  useDeleteSharedSupplierProductsMutation,
} = geoApi;
