import { createSlice, CreateSliceOptions } from "@reduxjs/toolkit";
import {
  Brand,
  BrandModel,
  Category,
  NewProduct,
  Product,
} from "../../dto/inventory";

export interface BrandModelFilters {
  subCatId: number;
  brandId: number;
}

interface PageFilters {
  querySearch?: string;
  kind?: string;
}

export interface InventoryState {
  currentProduct: NewProduct | null;
  productToDelete?: Product;
  products: Product[];
  categories: Category[];
  currentCategory?: Category;
  subCategories: Category[];
  panel: boolean;
  successModal: boolean;
  deleteModal: boolean;
  brands: Brand[];
  models: BrandModel[];
  nextPage: string | null;
  prevPage: string | null;
  filters: PageFilters;
  filtersPanelIsOpen: boolean;
}

const initialState: InventoryState = {
  currentProduct: null,
  products: [],
  categories: [],
  subCategories: [],
  brands: [],
  models: [],
  panel: false,
  successModal: false,
  deleteModal: false,
  nextPage: null,
  prevPage: null,
  filters: {},
  filtersPanelIsOpen: false,
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload;
    },
    setProductToDelete: (state, action) => {
      state.productToDelete = action.payload;
    },
    setFilters: (state, action) => {
      state.nextPage = null;
      state.prevPage = null;
      state.filters = action.payload;
    },
    setPanel: (state, action) => {
      state.panel = action.payload;
      if (!action.payload) {
        state.currentProduct = null;
      }
      state.subCategories = [];
      state.models = [];
    },
    setDeleteModal: (state, action) => {
      state.deleteModal = action.payload;
    },
    setSuccessModal: (state, action) => {
      state.successModal = action.payload;
    },
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload;
    },
    setFiltersPanel: (state, action) => {
      state.filtersPanelIsOpen = action.payload;
    },
  },
} as CreateSliceOptions);

export const {
  setPanel,
  setSuccessModal,
  setDeleteModal,
  setCurrentProduct,
  setFilters,
  setProductToDelete,
  setProductToEdit,
  clearPanel,
  setCurrentCategory,
  setFiltersPanel,
} = inventorySlice.actions;
export default inventorySlice.reducer;
