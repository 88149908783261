import { Point } from "@/lib/dto/inventory";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const apikey = 'AIzaSyAeFwi2LuypZ3yuLZDBPQwcLk_SnLqdftI'
const apikey = "AIzaSyAkx9jt_skcpiICX05irps8tBEfoK0_lNQ";

interface Result {
  formatted_address: string;
}

interface GMapResponse {
  results: Result[];
}

export const gmapApi = createApi({
  reducerPath: "gmapApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://maps.googleapis.com/maps/api",
  }),
  endpoints: (builder) => ({
    getAddress: builder.query<GMapResponse, Point>({
      query: (point) =>
        `/geocode/json?latlng=${point.lat},${point.lng}&key=${apikey}`,
    }),
  }),
});

export const { useGetAddressQuery } = gmapApi;
