import { Organization } from "../dto/organizations";
import { GoPage, ListPageResults } from "../dto/requests";
import { PAGE_SIZE } from "./config";
import { leadsApi } from "./leads";
import { inventoryApi } from "./inventory";

export const organizationsApi = inventoryApi.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllOrganizationsFiltered: builder.query<
      ListPageResults<Organization>,
      GoPage | null
    >({
      providesTags: ["Organization"],
      query: (goPage) => {
        const params = new URLSearchParams();
        params.append(
          "page_size",
          goPage?.page_size ? `${goPage?.page_size}` : PAGE_SIZE.toString()
        );
        if (goPage) {
          if (goPage.search) params.set("search", goPage.search);
          if (goPage.cursor) params.append("cursor", goPage.cursor);
          if (goPage.isPrevious) params.append("previous", "");
        }
        return `organizations?${params.toString()}`;
      },
    }),
    createOrganization: builder.mutation({
      query: (data) => ({
        url: "organizations",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Organization"],
    }),
    updateOrganization: builder.mutation<
      Organization,
      Partial<Organization> & Pick<Organization, "id">
    >({
      query: ({ id, ...data }) => ({
        url: `organizations/${id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Organization"],
    }),
    deleteOrganization: builder.mutation<void, number>({
      invalidatesTags: ["Organization"],
      query: (id) => ({
        url: `organizations/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetAllOrganizationsFilteredQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeleteOrganizationMutation,
} = organizationsApi;
