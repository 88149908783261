import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { API_HOST } from "../client/config";
import { GoPage, ListPageResults } from "../dto/requests";
import { Carrier } from "../dto/carriers";
import { getUserToken } from "../features/auth/services";

export const carriersApi = createApi({
  reducerPath: "carriersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_HOST}/suppliers/v100/`,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Token ${getUserToken()}`);
      headers.set("content-type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Carrier"],
  endpoints: (builder) => ({
    getCarriersFiltered: builder.query<ListPageResults<Carrier>, GoPage | void>(
      {
        query: (goPage) => {
          const params = new URLSearchParams();
          if (goPage) {
            if (goPage.search) params.set("search", goPage.search);
          }
          return `supplier-carriers?${params.toString()}`;
        },
      }
    ),
    createCarrier: builder.mutation({
      query: (carrier) => ({
        method: "POST",
        url: "supplier-carriers",
        body: carrier,
      }),
    }),
  }),
});

export const { useGetCarriersFilteredQuery, useCreateCarrierMutation } =
  carriersApi;
